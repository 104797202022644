<script>
export default {
  data() {
    return {
      configurations: [],
      allawanceLevels: [
        {
          name: "الأجر الإبتدائي",
          name_en: "Basic Salary",
          value: 0,
        },
        {
          name: "علاوة 1",
          name_en: "Allawance Level 1",
          value: 1,
        },
        {
          name: "علاوة 2",
          name_en: "Allawance Level 2",
          value: 2,
        },
        {
          name: "علاوة 3",
          name_en: "Allawance Level 3",
          value: 3,
        },
        {
          name: "علاوة 4",
          name_en: "Allawance Level 4",
          value: 4,
        },
        {
          name: "علاوة 5",
          name_en: "Allawance Level 5",
          value: 5,
        },
        {
          name: "علاوة 6",
          name_en: "Allawance Level 6",
          value: 6,
        },
      ],
      cashAccounts: [],
      emps: [],
      searchModel: "",
      Emps: {},
      selectedTap: true,
      emp_job: [],
      branch: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchEmps: null,
      EmpsSearchMode: false,
      addOptions: [{}],
      editOptions: [{}],
      option: "",
      data: {},
      editModalData: {},
      emp: {},
      edit: {},
      branch_id: null,

      // appprices:[],
    };
  },
  methods: {
    editemp(app) {
      this.edit = Object.assign({}, app);
      this.edit.birth_date = this.edit?.birth_date.split("T")[0];
      console.log("???", this.edit);
    },
    editconfigdata(config) {
      console.log(this.editModalData);
      this.editModalData = config;
      console.log("********", config);
      this.config = Object.assign([], config);
    },
    changeStatus(data) {
      console.log(data.target.value)
      if (data.target.value == "on")
        this.edit.has_social_insurance = 1
      else
        this.edit.has_social_insurance = 0

    },
    editconfig() {
      console.log(")))*****", this.addOptions);
      if (this.editModalData.option == "select") {
        this.http
          .post("emps/edit-col", {
            table: "emps",
            options: this.editOptions,
            config: this.editModalData,
            config_id: this.editModalData.id,
          })
          .then(() => {
            this.get(1);
          });
      } else {
        delete this.editModalData.options;
        this.http
          .post("emps/edit-col", {
            table: "emps",
            config: this.editModalData,
            config_id: this.editModalData.id,
          })
          .then(() => {
            this.get(1);
          });
      }
    },
    onChange(event) {
      console.log("_________", event.target.value);
      if (event.target.value == "select") {
        return (this.option = 3);
      }
    },
    addconfig() {
      if (this.data.option == "select") {
        this.http
          .post("emps/add-col", {
            table: "emps",
            data: this.data,
            options: this.addOptions,
          })
          .then(() => {
            this.getconfig(this.page);
          });
      } else {
        console.log("in else");
        console.log(this.data);
        this.http
          .post("emps/add-col", {
            table: "emps",
            data: this.data,
          })
          .then(() => {
            this.data = {};
            this.addOptions = [{}];
            this.getconfig(this.page);
          });
      }
    },
    deleteconfig(config) {
      var data = {
        title: "popups.deletecol",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("table-fileds", config.id).then(() => {
            console.log("############################3");
            this.getconfig(this.page);
          });
        }
      });
    },
    addEmps() {
      this.http
        .post("emps", {
          data: this.emp,
          types: this.configurations,
        })
        .then(() => {
          this.getconfig(1);
          this.get(1);
          // this.config = {};
          // this.addOptions = [{}];
        });
    },
    editEmps() {
      console.log(this.edit);
      let data = this.edit;
      console.log(data)
      this.http.put("emps", data.id, data).then(() => {
        this.get(1);
        this.getconfig(1);
        // this.config = {};
        // this.addOptions = [{}];
      });
    },
    search() {
      if (this.searchModel == "") {
        this.cancelappsearchMode();
        return;
      }
      this.EmpsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("emps/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.emps = res.data;
          this.emps.forEach((emp, index) => {
            let code = (this.page - 1) * this.limit + index + 1;
            emp.code =
              "EMP-" +
              (code < 10
                ? "000" + code
                : code < 100
                  ? "00" + code
                  : code < 1000
                    ? "0" + code
                    : code);
          });
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.EmpsSearchMode = false;
      this.get(this.page);
    },

    getEmps() {
      this.http.get("emps").then((res) => {
        this.emps = res.data;
      })
    },
    deleteEmps(app) {
      var data = {
        title: "popups.deleteemp",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("emps", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },
    getempsjob() {
      this.http.get("emp-job-titles").then((res) => {
        this.emp_job = res.data.map((t) => {
          return { value: t.id, lable: t.name, lable_en: t.name_en };
        });
      });
    },
    getbranch() {
      this.http.get("branches").then((res) => {
        this.branch = res.data.map((t) => {
          return { value: t.id, lable: t.name };
        });
      });
    },
    getEmpsCashAccounts() {
      this.http.post("transactions/cash-accounts", {}).then((res) => {
        this.cashAccounts = res.data;
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("emps/all-cols", {
          limit: this.limit,
          page: page,
          table: "emps",
          tables: ["emps", "table_fileds", "branches", "emp_job_titles"],
          fields: [
            "emps.*",
            "branches.name as branch_name ",
            "branches.name_en as branch_name_en ",
            "table_fileds.name as name_en",
            "table_fileds.name_ar as name_ar",
            "table_fileds.options as options",
            "emp_job_titles.name as title",
          ],
          where: [
            "table_fileds.table='emps'",
            "emps.branch_id=" + this.branch_id + " ",
            "branches.id=" + this.branch_id + " ",
            "emp_job_titles.id=emps.emp_job_title_id ",
          ],
          relations: [
            {
              table: "emps",
              key: "branch_id",
            },
          ],
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.emps = res.data;

          this.emps.forEach((emp, index) => {
            if (emp.has_social_insurance == 1) {
              console.log(index, emp.has_social_insurance)
              emp.has_social_insurance = true
            }
            else {
              console.log(index, emp.has_social_insurance)
              emp.has_social_insurance = false

            }
            let code = (this.page - 1) * this.limit + index + 1;
            emp.code =
              "EMP-" +
              (code < 10
                ? "000" + code
                : code < 100
                  ? "00" + code
                  : code < 1000
                    ? "0" + code
                    : code);
          });
          console.log("%%%%5", this.customers);
        });
    },
    getconfig(page) {
      console.log(page);
      this.http
        .post("emps/get-cols", {
          table: "emps",
        })
        .then((res) => {
          this.configurations = res.data;
          for (let i = 0; i < this.configurations.length; i++) {
            if (this.configurations[i].type == "int") {
              this.configurations[i].type = "number";
            }
            // this.configurations[i].options = JSON.parse(
            //   this.configurations[i].options
            // );
          }
          console.log("//////*******////", this.configurations);
        });
    },
  },
  created() {
    let currentUser = JSON.parse(localStorage.currentUser);
    this.branch_id = currentUser.branch_id;
    this.get(1);
    // this.getEmps();
    this.getempsjob();
    this.getconfig();
    this.getEmpsCashAccounts();
  },
};
</script>

<template>
  <!--emp tab-->
  <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'" class="row" v-if="selectedTap">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="search-box chat-search-box w-50">
              <div class="position-relative">
                <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
                  style="
                    background-color: #2a3042 !important;
                    wdith: 250px !important;
                  " />
                <i class="bx bx-search-alt search-icon text-light"></i>
              </div>
            </div>
          </div>
          <div class="col-6" style="display: flex; justify-content: flex-end">
            <button class="btn-close text-" v-if="EmpsSearchMode" @click="cancelappsearchMode()"></button>
            <button type="button" @click="selectedTap = !selectedTap" class="btn btn-light float-end mb-4 mx-2">
              <span class="bx bxs-cog float-end fa-2x" style="color: #2a3042 !important"></span>
            </button>
            <button type="button" data-bs-toggle="modal" data-bs-target="#addemp" class="btn btn-light float-end mb-4">
              <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="table table-centered table-nowrap table-striped table-hover align-middle" v-if="selectedTap">
            <thead>
              <tr class="text-light" style="background-color: #2a3042 !important">
                <th scope="col">#</th>
                <th scope="col">{{ $t("emps.code") }}</th>
                <th scope="col">{{ $t("emps.name") }}</th>
                <th scope="col">{{ $t("emps.designation") }}</th>
                <th scope="col">{{ $t("emps.branch") }}</th>
                <th scope="col">{{ $t("emps.tax") }}</th>
                <th scope="col">{{ $t("emps.can_attend") }}</th>
                <th scope="col">{{ $t("emps.allawance") }}</th>
                <th scope="col">{{ $t("emps.pay_method") }}</th>
                <th scope="col">{{ $t("emps.birth_date") }}</th>
                <th scope="col">{{ $t("emps.tel") }}</th>
                <th scope="col">{{ $t("emps.degree") }}</th>
                <th scope="col">{{ $t("emps.has_social_insurance") }}</th>
                <th scope="col" v-for="col in configurations" :key="col">
                  {{
                    $i18n.locale == "ar"
                    ? col.name_ar
                      ? col.name_ar
                      : col.name
                    : col.name
                      ? col.name
                      : col.name_ar
                  }}
                </th>
                <th scope="col">{{ $t("emps.created") }}</th>
                <th scope="col">{{ $t("emps.updated") }}</th>
                <th scope="col">{{ $t("emps.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, index) in emps" :key="app" class="">
                <td>{{ index + 1 }}</td>
                <td>{{ app?.code }}</td>
                <td>{{ app?.name }}</td>
                <td>
                  <span>{{
                    $i18n.locale == "ar"
                    ? app?.title
                    : app?.title_en
                      ? app?.title_en
                      : app?.title
                  }}</span>
                </td>
                <td>
                  {{
                    $i18n.locale == "ar"
                    ? app?.branch_name
                    : app?.branch_name_en
                  }}
                </td>
                <td>
                  <i v-if="app?.can_attend == 1" style="color: green" class="bx bx-check fa-2x"></i>
                  <i v-if="app?.can_attend == 0" style="color: red" class="bx bx-x fa-2x"></i>
                </td>
                <td v-if="app?.selected_allawance > 0">
                  <span>
                    {{
                      $i18n.locale == "ar"
                      ? `علاوة ${app.selected_allawance}`
                      : `Allawance Level ${app.selected_allawance}`
                    }}
                  </span>
                </td>
                <td v-if="app?.selected_allawance == 0">
                  <span>
                    {{
                      $i18n.locale == "ar" ? `الأجر الإبتدائي` : `Basic Salary`
                    }}
                  </span>
                </td>
                <td>
                  {{
                    $i18n.locale == "ar" ? app?.cash_name : app?.cash_name_en
                  }}
                </td>
                <td>{{ app?.birth_date?.split("T")[0] }}</td>
                <td>{{ app?.tel }}</td>
                <td>{{ app?.degree }}</td>
                <td>{{ app?.tax }}</td>
                <td>

                  <i v-if="app?.has_social_insurance" style="color: green" class="bx bx-check fa-2x"></i>
                  <i v-if="!app?.has_social_insurance" style="color: red" class="bx bx-x fa-2x"></i>
                </td>
                <td v-for="col in configurations" :key="col">
                  {{ app[col.field] ? app[col.field] : "-" }}
                </td>
                <td>{{ app?.created.split("T")[0] }}</td>
                <td>{{ app?.updated.split("T")[0] }}</td>
                <td>
                  <router-link class="text-white" :to="'/emps/profile/' + app?.id">
                    <button class="btn btn-primary" style="margin-inline-end: 4px">
                      {{ $t("popups.profile") }}
                    </button>
                  </router-link>
                  <button class="btn btn-primary" style="margin-inline-end: 4px" @click="editemp(app)"
                    href="javascript: void(0);" data-bs-toggle="modal" data-bs-target="#editemps" role="button">
                    {{ $t("popups.edit") }}
                  </button>
                  <button class="btn btn-danger" @click="deleteEmps(app)">
                    {{ $t("popups.delete") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="selectedTap">
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--   Apps  Pagination   -->
  <div class="row">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12" v-if="!selectedTap">
            <button class="btn-close text-" v-if="EmpsSearchMode" @click="cancelappsearchMode()"></button>
            <button type="button" class="btn btn-light float-end mb-4" data-bs-toggle="modal" data-bs-target="#add">
              <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
            </button>
            <button type="button" @click="selectedTap = !selectedTap" class="btn btn-light float-end mb-4">
              <span class="bx bxs-user float-start fa-2x" style="color: #2a3042 !important"></span>
            </button>
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table table-striped table-hover mb-0"
          v-if="!selectedTap">
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("config.id") }}</th>
              <th scope="col">{{ $t("config.ar") }}</th>
              <th scope="col">{{ $t("config.en") }}</th>

              <th scope="col">{{ $t("config.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(config, index) in configurations" :key="config" class="">
              <td>{{ index + 1 }}</td>
              <td>{{ config.name_ar }}</td>
              <td>{{ config.name }}</td>

              <td>
                <a class="btn btn-primary me-2" @click="editconfigdata(config)" href="javascript: void(0);" role="button"
                  data-bs-toggle="modal" data-bs-target="#edit">{{ $t("popups.edit") }}</a>
                <a class="btn btn-danger me-2" href="javascript: void(0);" role="button" @click="deleteconfig(config)">{{
                  $t("popups.delete") }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--  start add new col modal-->
  <div class="modal fade" id="add" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container px-0">
            <form @submit.prevent="addconfig()">
              <div class="modal-header">
                <h5 class="modal-title mt-3" id="exampleModalLabel">
                  {{ $t("popups.adacol") }}
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  style="position: relative; left: 10px"></button>
              </div>
              <div class="mb-3 mt-4">
                <label for="exampleInputEmail1" class="form-label">Arbic Name</label>
                <input type="text" v-model="data.ar" class="form-control" id="exampleInputEmail1"
                  aria-describedby="emailHelp" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">English Name</label>
                <input type="text" v-model="data.en" class="form-control" id="exampleInputPassword1" />
              </div>
              <label for="exampleInputEmail1" class="form-label">Type</label>
              <select class="form-select" @change="onChange($event)" v-model="data.option"
                aria-label="Default select example">
                <option value="number">Number</option>
                <option value="text">Text</option>
                <option value="date">Date</option>
                <option value="select">Options</option>
              </select>
              <div v-if="this.option == 3">
                <button type="button" @click="addOptions.push({})" class="btn float-end">
                  <span class="bx bxs-plus-circle float-end fa-2x mb-2 mt-2" style="color: #2a3042 !important"></span>
                </button>
                <div v-for="option in addOptions" :key="option">
                  <input type="text" v-model="option.oneoption" class="form-control mb-2" id="exampleInputPassword1" />
                </div>
              </div>
              <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                  {{ $t("popups.add") }}
                </button>
                <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end add new col modal -->

  <!--start edit new col modal-->
  <div class="modal fade" id="edit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container px-0">
            <form @submit.prevent="editconfig()">
              <div class="modal-header">
                <h5 class="modal-title mt-3" id="exampleModalLabel">
                  {{ $t("popups.adacol") }}
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  style="position: relative; left: 10px"></button>
              </div>
              <div class="mb-3 mt-4">
                <label for="exampleInputEmail1" class="form-label">Arbic Name</label>
                <input type="text" v-model="editModalData.name_ar" class="form-control" id="exampleInputEmail1"
                  aria-describedby="emailHelp" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">English Name</label>
                <input type="text" v-model="editModalData.name" class="form-control" id="exampleInputPassword1" />
              </div>
              <label for="exampleInputEmail1" class="form-label">Type</label>
              <select class="form-select" @change="onChange($event)" v-model="editModalData.option"
                aria-label="Default select example">
                <option value="number">Number</option>
                <option value="text">text</option>
                <option value="date">Date</option>
                <option value="select">Options</option>
              </select>
              <div v-if="this.option == 3">
                <button type="button" @click="editOptions.push({})" class="btn float-end">
                  <span class="bx bxs-plus-circle float-end fa-2x mb-2 mt-2" style="color: #2a3042 !important"></span>
                </button>
                <div v-for="option in editOptions" :key="option">
                  <input type="text" v-model="option.oneoption" class="form-control mb-2" id="exampleInputPassword1" />
                </div>
              </div>
              <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                  {{ $t("popups.edit") }}
                </button>
                <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end edit new col modal-->
  <!--add emp-->
  <div class="modal fade" id="addemp" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container px-0">
            <form @submit.prevent="addEmps()">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ $t("popups.addemp") }}
                </h5>
                <button type="button" class="btn-close" style="margin: 0" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>

              <div class="mb-3 mt-4">
                <label for="exampleInputEmail1" class="form-label">
                  {{ $t("popups.designation") }}
                </label>
                <select class="form-select" aria-label="Default select example" v-model="emp.emp_job_title_id">
                  <option v-for="i in emp_job" :key="i" :value="i.value">
                    {{
                      $i18n.locale == "ar"
                      ? i.lable
                      : i.lable_en
                        ? i.lable_en
                        : i.lable
                    }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.can_attend") }}
                </label>

                <select class="form-select" aria-label="Default select example" v-model="emp.can_attend">
                  <option value="1">{{ $t("popups.yes") }}</option>
                  <option value="0">{{ $t("popups.no") }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.allawance") }}
                </label>

                <select class="form-select" aria-label="Default select example" v-model="emp.selected_allawance">
                  <option v-for="allawance in allawanceLevels" :key="allawance" :value="allawance.value">
                    {{
                      $i18n.locale == "ar" ? allawance.name : allawance.name_en
                    }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("popups.name") }}
                </label>
                <input type="text" v-model="emp.name" class="form-control" id="exampleInputPassword1" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.birth_date") }}
                </label>
                <input type="date" v-model="emp.birth_date" class="form-control" id="exampleInputPassword1" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("popups.telephone") }}
                </label>
                <input type="text" v-model="emp.tel" class="form-control" id="exampleInputPassword1" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.degree") }}
                </label>
                <select class="form-select" v-model="emp.degree" aria-label="Default select example">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.tax") }}
                </label>
                <input type="number" v-model="emp.tax" class="form-control" id="exampleInputPassword1" />
              </div>

              <div class="form-checkbox">
                <input class="form-check-input mx-2" type="checkbox"  style="width:20px;height:20px;" v-model="emp.has_social_insurance"
                  v-bind:id="emp.id" />
                <label for="exampleInputEmail1" class=" form-check-label">
                 <title class="title"> {{ $t("popups.has_social_insurance") }}
                </title>
                </label>

              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  {{ $t("emps.pay_method") }}
                </label>
                <select class="form-select" aria-label="Default select example" v-model="emp.cash_account_id">
                  <option v-for="i in cashAccounts" :key="i" :value="i.cash_account_id">
                    {{ $i18n.locale == "ar" ? i?.name : i?.name_en }}
                  </option>
                </select>
              </div>

              <div class="mb-3" v-for="i in configurations" :key="i">
                <div v-if="i.type != 'select'">
                  <label for="exampleInputPassword1" class="form-label">{{
                    $i18n.locale == "ar"
                    ? i.name_ar
                      ? i.name_ar
                      : i.name
                    : i.name
                      ? i.name
                      : i.name_ar
                  }}
                  </label>
                  <input :type="i.type" v-model="i.extra" class="form-control" id="exampleInputPassword1" />
                </div>

                <div v-else>
                  <label for="exampleInputPassword1" class="form-label">{{
                    $i18n.locale == "ar"
                    ? i.name_ar
                      ? i.name_ar
                      : i.name
                    : i.name
                      ? i.name
                      : i.name_ar
                  }}</label>
                  <select class="form-select" aria-label="Default select example">
                    <option v-for="item in i.options" :key="item" :value="item.oneoption">
                      {{ item.oneoption }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                <button type="submit" class="btn btn-primary">
                  {{ $t("popups.add") }}
                </button>
                <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end add emp-->

  <!--edit emp-->
  <div class="modal fade" id="editemps" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container px-0">
            <form @submit.prevent="editEmps()">
              <div class="modal-header">
                <h5 class="modal-title mt-3" id="exampleModalLabel">
                  {{ $t("popups.edit") }}
                </h5>
                <button type="button" class="btn-close" style="margin: 0" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>

              <div class="mb-3 mt-4">
                <label for="exampleInputEmail1" class="form-label">
                  {{ $t("popups.designation") }}
                </label>
                <select class="form-select" aria-label="Default select example" v-model="edit.emp_job_title_id">
                  <option :selected="parseInt(edit.emp_job_title_id) == parseInt(i.value)
                    " v-for="i in emp_job" :key="i" :value="i.value">
                    {{
                      $i18n.locale == "ar"
                      ? i.lable
                      : i.lable_en
                        ? i.lable_en
                        : i.lable
                    }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.can_attend") }}
                </label>

                <select class="form-select" aria-label="Default select example" v-model="edit.can_attend">
                  <option value="1">{{ $t("popups.yes") }}</option>
                  <option value="0">{{ $t("popups.no") }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.allawance") }}
                </label>

                <select class="form-select" aria-label="Default select example" v-model="edit.selected_allawance">
                  <option :selected="edit.selected_allawance == allawance.value" v-for="allawance in allawanceLevels"
                    :value="allawance.value" :key="allawance">
                    {{
                      $i18n.locale == "ar" ? allawance.name : allawance.name_en
                    }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("popups.name") }}
                </label>
                <input type="text" v-model="edit.name" class="form-control" id="exampleInputPassword1" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.birth_date") }}
                </label>
                <input type="date" v-model="edit.birth_date" class="form-control" id="exampleInputPassword1" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("popups.telephone") }}
                </label>
                <input type="text" v-model="edit.tel" class="form-control" id="exampleInputPassword1" />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.degree") }}
                </label>
                <select class="form-select" v-model="edit.degree" aria-label="Default select example">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="14">15</option>
                  <option value="14">16</option>
                  <option value="14">17</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  {{ $t("emps.tax") }}
                </label>
                <input type="text" v-model="edit.tax" class="form-control" id="exampleInputPassword1" />
              </div>

              <div class="form-checkbox">
                <input v-if="emp.has_social_insurance" class="form-check-input mx-2" @change="changeStatus($event)"
                  type="checkbox" v-model="emp.has_social_insurance" :checked="true" />
                <input v-if="!emp.has_social_insurance" class="form-check-input mx-2" @change="changeStatus($event)"
                  type="checkbox" v-model="emp.has_social_insurance" />
                <label for="exampleInputEmail1" class=" form-check-label">
                  {{ $t("popups.has_social_insurance") }}
                </label>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  {{ $t("emps.pay_method") }}
                </label>
                <select required class="form-select" aria-label="Default select example" v-model="edit.cash_account_id">
                  <option v-for="i in cashAccounts" :key="i" :value="i.cash_account_id">
                    {{ $i18n.locale == "ar" ? i?.name : i?.name_en }}
                  </option>
                </select>
              </div>
              <br />
              <div class="mb-3" v-for="i in configurations" :key="i">
                <div v-if="i.type != 'select'">
                  <label for="exampleInputPassword1" class="form-label">{{
                    $i18n.locale == "ar"
                    ? i.name_ar
                      ? i.name_ar
                      : i.name
                    : i.name
                      ? i.name
                      : i.name_ar
                  }}</label>
                  <input :type="i.type" v-model="i.extra" class="form-control" id="exampleInputPassword1" />
                </div>

                <div v-else>
                  <label for="exampleInputPassword1" class="form-label">{{
                    i.name
                  }}</label>
                  <select class="form-select" aria-label="Default select example" v-model="i.edit">
                    <option v-for="item in i.options" :key="item" :value="item.oneoption">
                      {{ item.oneoption }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                  {{ $t("popups.edit") }}
                </button>
                <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                  {{ $t("popups.cancel") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end edit emp-->
</template>
<style scoped>
/* .btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
} */

/* .add {
  position: relative;
  font-size: 12px;
  top: 25px;
  padding: 0px;
  margin: 0;
  margin-top: -22px;
  margin-top: -25px;
} */
</style>
